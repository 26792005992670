*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#btn-mas{
    display: none;
}
.containerBtn{
    position: fixed;
	bottom: 40px;
	right: 40px;
    z-index: 99;
    width: 40px;
    
}

.redes a{
    display: block;
    text-decoration: none;
    background:#fff;
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.4);
    transition: all 500ms ease;
}
.icon-mas2{
    display: block;
    text-decoration: none;
    background:#22c33f;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50%;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.4);
    transition: all 500ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.redes a > img{
    margin-bottom: -6px;
}
.redes a > img:hover {
    width: 30px;
    margin-bottom: -11px;
}
.redes a{
    margin-bottom: -15px;
    opacity: 0;
    visibility: hidden;
}

#btn-mas:checked~ .redes a{
    margin-bottom: 10px;
    opacity: 1;
    visibility: visible;
}
.icon-mas2{
    cursor: pointer;
    background: #22c33f;
    font-size: 32px;
}
#btn-mas:checked~ .btn-mas .icon-mas2{
    transform: rotate(137deg);
    font-size: 25px;
}

.redes a:hover{
    width: 58px;
    height: 58px;
}