.card-media-service{
    position: relative;
}
.card-media-content-btn{
    position: absolute; 
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}
.card-media-content-btn:hover{
    background: rgba(0, 0, 0, 0.5);

}

.card-media-content-btn > h5 {
    transition: ease-out 0.5s;
    color: transparent;
}
.card-media-content-btn:hover >h5 {
   color: #fff;
   padding: 8px;
   border: 3px solid #fff;
}

.btn-pedido{
    /* background-color: #22c33f !important; */
    /* background: linear-gradient(90deg, #1161D1 0%, #599BF7 100%); */
    background: #22c33f;
    width: 100%;
    border-radius: 0px !important;
    font-weight: 700 !important;
}

.cardProduc:hover{
    box-shadow: "2px 5px 5px #999";
    transform: translateY(-3%);
}


