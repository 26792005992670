/* 2ecc71  44AA00 */

/* #titleDescriptionStart {
  background: 50% 100%/50% 50% no-repeat radial-gradient(ellipse at bottom, #fff, #fff, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-animation: reveal 3000ms ease-in-out forwards 200ms, glow 2500ms linear infinite 2000ms;
          animation: reveal 3000ms ease-in-out forwards 200ms, glow 2500ms linear infinite 2000ms;
} */
@-webkit-keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@-webkit-keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}
@keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}