
@media only screen and (max-width: 750px) {  
    .images-carrousel-present{
        width: 600px;
    }
}
@media only screen and (max-width: 650px) {  
    .images-carrousel-present{
        width: 500px;
    }
}
@media only screen and (max-width: 550px) {  
    .images-carrousel-present{
        width: 410px;
    }
}
@media only screen and (max-width: 450px) {  
    .images-carrousel-present{
        width: 380px;
    }
}

@media only screen and (max-width: 420px) {  
    .images-carrousel-present{
        width: 350px;
    }
}

@media only screen and (max-width: 400px) {  
    .images-carrousel-present{
        width: 330px;
    }
}